import React from 'react'
import { Link } from "gatsby"

import logo from '../img/logo-icon.png'

export default  () => (
  <div style={{ paddingTop: '90px' }}>
    <nav className="navbar is-fixed-top">
      <div className="navbar-brand">
        <Link to="/" className="navbar-item fancy-text">
          <div className="column">
            <img src={logo} alt="Logo" />
          </div>
          <div className="column">
            Diazanne
          </div>
        </Link>
        <div id="the-navbar-burger" className="navbar-burger burger" data-target="navbarExampleTransparentExample">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div id="navbarExampleTransparentExample" className="navbar-menu">
        <div className="navbar-start"  id="navMenu">
            <Link className="navbar-item" to="/qui-je-suis">
              Qui Je Suis
            </Link>
            <Link className="navbar-item" to="/#services">
              Services
            </Link>
            <Link className="navbar-item" to="/histoire-de-naissance">
              Histoire de Naissance
            </Link>
            <Link className="navbar-item" to="/blog">
              Blog
            </Link>
            <Link className="navbar-item" to="/contact-form">
              Contact
            </Link>
          </div>
      </div>
    </nav>
  </div>
)
