import React from 'react'
import FacebookLogo from '../img/facebook_icon.png'

export default () => (
  <div style={{ paddingTop: '30px' }}>
    <footer className="footer">
      <div className="content columns">
        <p className="column is-one-fifth">
          Adresse
          <br/>
          16 rue Paul Langevin - 69200 
          <br/>
          VENISSIEUX
        </p>
        <div className="column is-two-fifths"></div>
        <p className="column is-two-fifth">
          <a href="https://www.facebook.com/anne.diaz.98871">
            <img style={{maxHeight: '80px', float: 'left', padding: '10px'}} src={FacebookLogo} alt="lien au page facebook"/>
          </a>
          SIRET - 520 190 539 00012 - APE 9604Z 
          <br/>
          Gsm. 06 89 61 15 09 - Tél : 09 50 05 71 65
          <br/>
          Mail : diazanne@hotmail.fr
        </p>
      </div>
    </footer>
  </div>
)