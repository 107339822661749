import React from 'react'
import Helmet from 'react-helmet'

import Navbar from './Navbar'
import Footer from './Footer'

// TODO: fix the hight issue so the Navbar doesn affect anything
const Layout = ({ children }) => (
  <div> 
    <Helmet>
      <title>Diazanne</title>
      <link href="https://fonts.googleapis.com/css?family=Great+Vibes" rel="stylesheet" />
    </Helmet>
    <Navbar />
    <div>{children}</div>
    <Footer />
  </div>
)

export default Layout
